import dayjs from "dayjs";

export function convertThousandNumbers(num, isPrice) {
    if (!isNaN(num)) {
        let amount = isPrice ? parseFloat(num).toFixed(2) : parseFloat(num);
        let formatted = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return formatted;
    } else return num ? num : 0;
}

// Allow digits and '.' only, using a RegExp
export function inputNumberValidation(value) {
    return /^\d*\.?\d*$/.test(value);
}

export function formatDate(date){
    var date =  new Date(date);
    return date.getFullYear()     + '-' + 
        lpad( (date.getMonth() + 1) )   + '-' + 
        lpad( date.getDate() )          + ' ' + 
        lpad( date.getHours() )         + ':' + 
        lpad( date.getMinutes() )       + ':' +
        lpad( date.getSeconds() )
    ;
}

export function lpad (val, pad = 2, padString = "0"){
    let string = ''; 
    for(let i = 0 ; i < pad ; i++) string += padString;
    return (string + val).slice(-pad)
};

//format datetime as 2023-12-31 23:59:59
export function formatDateTime(date){
    return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
}

// Function to export data as a file
export const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
};

export function pkTypeList() {
    const pkType = [
        { value: 30, label: "1V1 Friend PK" }, { value: 31, label: "3V3 Friend PK" },
        { value: 20, label: "1V1 Random PK" }, { value: 21, label: "3V3 Random PK" }
    ]
    return pkType;
}

export function pkTimeList() {
    const pkTime = [
        { value: 1, label: "1 Minute PK" }, { value: 2, label: "2 Minute PK" },
        { value: 3, label: "5 Minute PK" }, { value: 4, label: "10 Minute PK" }
    ]
    return pkTime;
}

export function pkResultList() {
    const pkResult = [
        { value: 1, label: "Team 1" }, { value: 2, label: "Team 2" },
        { value: 90, label: "Draw" },
    ]
    return pkResult;
}