import React, { useState, useEffect } from 'react';

// routing
import { NavLink as Link } from "react-router-dom";

// alert
import { warning } from "../../util/Alert";

// redux
import { useDispatch } from "react-redux";

// types
import { UNSET_ADMIN } from "../../store/admin/types";

//MUI
import { makeStyles } from "@material-ui/core";

// jquery
import $ from "jquery";
// css
import "../../assets/css/custom.css";

const useStyles = makeStyles(() => ({
  navLink: {
    "&.active": {
      color: "#E85382 !important",
      fontWeight: 500,
      fontSize: 16,
    },
    "&.active span": {
      color: "#E85382 !important",
      fontWeight: 900,
    },
  },
}));

const Navbar = () => {
  const [ulVisibility, setUlVisibility] = useState({
    planSubMenu: false,
    planHistorySubMenu : false,
    giftSubMenu : false,
    complainSubMenu : false,
    reportSubMenu : false

  });
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleLogout = () => {
    const data = warning();
    data.then((isLogout) => {
      if (isLogout) {
        dispatch({ type: UNSET_ADMIN });
        window.location.href = "/";
      }
    });
  };
  const handleLinkClick = (ulId, iconId) => {
    const iconElement = document.getElementById(iconId);

    // 切换旋转类名
    iconElement.classList.toggle('rotate-icon');

    setUlVisibility((prevVisibility) => ({
      ...prevVisibility,
      [ulId]: !prevVisibility[ulId],
    }));
  };

  useEffect(() => {
    $("").addClass("submenu-margin");
  }, []);
  return (
    <>
      <div className="page-sidebar">
        <span className="logo text-danger">CeleLive</span>
        <ul className="list-unstyled accordion-menu">
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Dashboard"
          >
            <Link to="/admin/dashboard" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="tabler:activity"></span>
              </span>
              Dashboard
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="User">
            <Link to="/admin/user" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="ph:users"></span>
              </span>
              User
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Agency">
            <Link to="/admin/agency" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="ph:user"></span>
              </span>
              Agency
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Live Summary">
            <Link to="/admin/streamerLiveSummary" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="carbon:report"></span>
              </span>
              Live Summary
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Wallet">
            <Link to="/admin/wallet" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="fe:wallet"></span>
              </span>
              Wallet
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Banner">
            <Link to="/admin/banner" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="material-symbols:planner-banner-ad-pt-outline"></span>
              </span>
              Banner
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Ranking">
            <Link to="/admin/Ranking" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="material-symbols:hotel-class"></span>
              </span>
              Ranking
            </Link>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Plan"
            className="pointer-cursor"
          >
            <a
              href="#"
              className={`add-collapse-margin ${ulVisibility.planSubMenu ? 'active' : ''}`}
              style={{ marginLeft: 0 }}
              onClick={() => handleLinkClick('planSubMenu', 'planSubMenuIcon1')}
            >
              <span className="sidenav__icon">
                <span className="iconify" data-icon="icon-park-outline:plan"></span>
              </span>
              Plan
              <i
                id="planSubMenuIcon1"
                className="fas fa-chevron-right dropdown-icon"
              ></i>
            </a>
            <ul id="planSubMenu" style={{ display: ulVisibility.planSubMenu ? 'block' : 'none' }}>
              <li>
                <Link to="/admin/coinPlan" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>Coin Plan
                </Link>
              </li>
              <li>
                <Link to="/admin/vipPlan" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>VIP Plan
                </Link>
              </li>
            </ul>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Purchase Plan History"
            className="pointer-cursor"
          >
            <a
              href="#"
              className={`add-collapse-margin ${ulVisibility.planHistorySubMenu ? 'active' : ''}`}
              style={{ marginLeft: 0 }}
              onClick={() => handleLinkClick('planHistorySubMenu', 'planHitroySubMenuIcon1')}
            >
              <span className="sidenav__icon">
                <span className="iconify" data-icon="material-symbols:history"></span>
              </span>
              Plan History
              <i
                id="planHitroySubMenuIcon1"
                className="fas fa-chevron-right dropdown-icon"
              ></i>
            </a>
            <ul id="planHistorySubMenu" style={{ display: ulVisibility.planHistorySubMenu ? 'block' : 'none' }}>
              <li>
                <Link
                  to="/admin/coinPlan/history"
                  className={`${classes.navLink}`}
                >
                  <i className="far fa-circle"></i>Coin Plan
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/vipPlan/history"
                  className={`${classes.navLink}`}
                >
                  <i className="far fa-circle"></i>VIP Plan
                </Link>
              </li>
            </ul>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Gift"
            className="pointer-cursor"
          >
            <a
              href="#"
              className={`add-collapse-margin ${ulVisibility.giftSubMenu ? 'active' : ''}`}
              style={{ marginLeft: 0 }}
              onClick={() => handleLinkClick('giftSubMenu', 'giftSubMenuIcon1')}
            >
              <span className="sidenav__icon">
                <span className="iconify" data-icon="mdi:gift-outline"></span>
              </span>
              Gift
              <i
                id="giftSubMenuIcon1"
                className="fas fa-chevron-right dropdown-icon"
              ></i>
            </a>
            <ul id="giftSubMenu" style={{ display: ulVisibility.giftSubMenu ? 'block' : 'none' }}>
              <li>
                <Link
                  to="/admin/giftCategory"
                  className={`${classes.navLink}`}
                  onClick={() => localStorage.removeItem("GiftClick")}
                >
                  <i className="far fa-circle"></i>Category
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/gift"
                  className={`${classes.navLink}`}
                  onClick={() => localStorage.setItem("GiftClick", true)}
                >
                  <i className="far fa-circle"></i>Gift
                </Link>
              </li>
            </ul>
          </li>

          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Sticker">
            <Link to="/admin/sticker" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="material-symbols:anchor"></span>
              </span>
              Sticker
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Song">
            <Link to="/admin/song" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="tabler:music"></span>
              </span>
              Song
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Game">
            <Link to="/admin/Game" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="material-symbols:stadia-controller"></span>
              </span>
              Game
            </Link>
          </li>
          {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="Hashtag">
            <Link to="/admin/hashtag" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="ph:hash"></span>
              </span>
              Hashtag
            </Link>
          </li> */}
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Level">
            <Link to="/admin/level" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="mdi:signal"></span>
              </span>
              Level
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Post">
            <Link to="/admin/post" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="ic:baseline-post-add"></span>
              </span>
              Post
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Video">
            <Link to="/admin/video" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="gridicons:video"></span>
              </span>
              Video
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Reported User">
            <Link to="/admin/reportedUser" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="mingcute:report-line"></span>
              </span>
              Reported User
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Announcement">
            <Link to="/admin/announcement" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="carbon:report"></span>
              </span>Announcement
            </Link>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Complain"
            className="pointer-cursor"
          >
            <a
              href="#"
              className={`add-collapse-margin ${ulVisibility.complainSubMenu ? 'active' : ''}`}
              style={{ marginLeft: 0 }}
              onClick={() => handleLinkClick('complainSubMenu', 'complainSubMenuIcon1')}
            >
              <span className="sidenav__icon">
                <span className="iconify" data-icon="codicon:report"></span>
              </span>
              Complain
              <i
                id="complainSubMenuIcon1"
                className="fas fa-chevron-right dropdown-icon"
              ></i>
            </a>
            <ul id="complainSubMenu" style={{ display: ulVisibility.complainSubMenu ? 'block' : 'none' }}>
              <li>
                <Link
                  to="/admin/pendingComplain"
                  className={`${classes.navLink}`}
                >
                  <i className="far fa-circle"></i>Pending
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/solvedComplain"
                  className={`${classes.navLink}`}
                >
                  <i className="far fa-circle"></i>Solved
                </Link>
              </li>
            </ul>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Redeem"
            className="pointer-cursor"
          >
           <a
              href="#"
              className={`add-collapse-margin ${ulVisibility.reportSubMenu ? 'active' : ''}`}
              style={{ marginLeft: 0 }}
              onClick={() => handleLinkClick('reportSubMenu', 'reportSubMenuIcon1')}
            >
              <span className="sidenav__icon">
                <span className="iconify" data-icon="lucide:key-round"></span>
              </span>
              Redeem
              <i
                id="reportSubMenuIcon1"
                className="fas fa-chevron-right dropdown-icon"
              ></i>
            </a>
            <ul id="reportSubMenu" style={{ display: ulVisibility.reportSubMenu ? 'block' : 'none' }}>
              <li>
                <Link
                  to="/admin/pendingRedeem"
                  className={`${classes.navLink}`}
                >
                  <i className="far fa-circle"></i>Pending
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/AcceptedRedeem"
                  className={`${classes.navLink}`}
                >
                  <i className="far fa-circle"></i>Accepted
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/declineRedeem"
                  className={`${classes.navLink}`}
                >
                  <i className="far fa-circle"></i>Declined
                </Link>
              </li>
            </ul>
          </li>

          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Report"
          >
            <Link to="/admin/report" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="carbon:report"></span>
              </span>
              Report
            </Link>
          </li>

          {/* <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Google Ad"
          >
            <Link to="/admin/advertisement" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="book"></i>
              </span>
              Google Ad
            </Link>
          </li> */}
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Setting">
            <Link to="/admin/Setting" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="ic:outline-settings"></span>
              </span>
              Setting
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Profile">
            <Link to="/admin/profile" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <span className="iconify" data-icon="carbon:user-profile"></span>
              </span>
              Profile
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Logout">
            <a
              href={() => false}
              onClick={handleLogout}
              className="add-collapse-margin"
            >
              <span className="iconify" data-icon="material-symbols:logout"></span>
              Logout
            </a>
          </li>
        </ul>
        <a
          href={() => false}
          id="sidebar-collapsed-toggle"
          style={{ opacity: 0, pointerEvents: "none" }}
        >
          <i data-feather="arrow-right"></i>
        </a>
      </div>
    </>
  );
};
export default Navbar;
